.dashboard {
  background-color: black;
  color: white;
  background-image: url('../../assets/imgs/background-image.webp') !important;
  background-size: cover;
  background-position: center;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -webkit-background-attachment: fixed;
  height: 100vh;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 4.375rem;
  border-bottom-right-radius: 4.375rem;
  padding-bottom: 60px;
  position: relative;

  .information {
    background: linear-gradient(205deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 75%);
    z-index: 900;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    padding-bottom: 60px;
    border-bottom-left-radius: 4.375rem;
    border-bottom-right-radius: 4.375rem;

    .title {
      font-size: 50px;
      margin-left: 50px;
      font-weight: 800;
      -webkit-font-smoothing: antialiased;
      line-height: 0.9;

      @media screen and (max-width: 650px) {
        font-size: 35px;
      }
      @media screen and (max-width: 450px) {
        margin-left: 35px;
        font-size: 30px;
      }
      @media screen and (max-width: 380px) {
        margin-left: 20px;
        font-size: 26px;
      }

      .middle-text {
        -webkit-text-stroke: 1px #fff;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

@supports (-webkit-touch-callout: none) {
  .dashboard {
    background-attachment: scroll;
  }
}
