.header {
  height: 86px;
  z-index: 9500;
  position: fixed;
  width: 100%;
  color: white;
  top: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0.7) 100%);

  @media screen and (max-width: 650px) {
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.7) 100%);
  }

  .header-container {
    padding: 0 20px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 100%;

    @media screen and (max-width: 450px) {
      padding: 0 8px;
    }

    .menu {
      display: flex;
      align-items: center;

      .menu-item {
        padding: 4px 14px;
        font-size: 14px;
        font-weight: bold;
        border-radius: 30px;
        background-color: white;
        color: black;
        border: 1px solid white;
        transition: all 0.5s;

        @media screen and (max-width: 650px) {
          font-size: 12px;
          padding: 3px 8px;
        }
        @media screen and (max-width: 480px) {
          padding: 3px 6px;
          font-size: 11.5px;
        }
        @media screen and (max-width: 380px) {
          padding: 3px 5px;
          font-size: 11px;
        }

        &:hover {
          transition: all 0.5s;
          background-color: black;
          color: orange;
          border: 1px solid orange;
          cursor: pointer;
          box-shadow: 0 0 15px orange;
        }
      }

      .menu-contact {
        margin-left: 15px;

        @media screen and (max-width: 450px) {
          margin-left: 8px;
        }
      }
    }
  }

  .logo-container {
    display: flex;
    align-items: center;

    .logo {
      border-top-right-radius: 20%;
      border-top-right-radius: 35%;
      border-bottom-right-radius: 20%;
      border-bottom-right-radius: 35%;
      border-top-left-radius: 5%;
      border-bottom-left-radius: 5%;
      z-index: 1000;

      @media screen and (max-width: 650px) {
        width: 45px;
      }
      @media screen and (max-width: 450px) {
        width: 28px;
      }
      @media screen and (max-width: 350px) {
        width: 26px;
        display: none;
      }
    }
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 650px) {
    margin-left: -12px;
  }
  @media screen and (max-width: 350px) {
    margin-left: -10px;
  }

  h1.glitch {
    color: white;
    font-family: sans-serif;
    font-weight: 800;
    position: relative;
    font-size: 30px;

    @media screen and (max-width: 650px) {
      font-size: 24px;
    }
    @media screen and (max-width: 450px) {
      font-size: 20px;
    }

    padding: 5px 20px;
  }

  h1.glitch::before,
  h1.glitch::after {
    padding: 5px 20px;
    color: white;
    content: 'DEVORRIO';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    overflow: hidden;
    top: 0;
  }

  h1.glitch::before {
    left: 3px;
    text-shadow: -1.5px 0 orange;
    animation-name: glitch-animation-1;
    animation-duration: 7.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: reverse-alternate;
  }

  h1.glitch::after {
    left: -3px;
    text-shadow: -1.5px 0 #00e7ff;
    animation-name: glitch-animation-1;
    animation-duration: 8s;
    animation-timing-function: linear;
    animation-delay: 5s;
    animation-iteration-count: infinite;
    animation-direction: reverse-alternate;
  }
}

@mixin apply-clip($step) {
  @if $step > 30 {
    clip: rect(random(1) + px, 1px, random(1) + px, 1px);
  }
  @if $step < 31 {
    clip: rect(random(50) + px, 200px, random(50) + px, 20px);
  }
}

@keyframes glitch-animation-1 {
  $steps: 100;

  @for $i from 0 through $steps {
    $percentage: percentage($i * (1 / $steps));
    #{ $percentage } {
      @include apply-clip($i);
    }
  }
}
