body {
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background-color: white;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #00afbe;
  border-radius: 10px;
}
